/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --my-account-order-list-divider-background: var(--secondary-dark-color);
    --my-account-order-list-table-head-background: var(--secondary-base-color);
    --my-account-order-list-search-heading-background: var(--my-account-order-list-table-head-background);
    --my-account-order-list-search-field-background: var(--my-account-order-list-table-head-background);
    --my-account-order-list-search-wrapper-background: white;
    --my-account-order-list-table-head-text-color: #0a0a0a;
}

.MyAccountOrderList {
    margin-bottom: var(--header-nav-height);

    &-ActionBar {
        display: flex;
        padding: 0 0 1.2rem;
        justify-content: flex-end;

        @include desktop {
            position: absolute;
            top: -55px;
            right: 0;
        }

        @include mobile {
            background-color: var(--my-account-content-background);
            bottom: var(--navigation-tabs-height);
            display: block;
            left: 0;
            padding: 1.4rem 1.4rem;
            position: fixed;
            width: 100%;
            z-index: 999;
            bottom: 0;
        }

        &_isBottom {
            @include desktop {
                position: relative;
                top: unset;
                display: flex;
                justify-content: flex-end;
                margin-top: 2.4rem;
                padding: 0;
            }
        }
    }

    &-Search {
        z-index: 10;
        margin-bottom: 2rem;

        .SearchField {
            width: auto;
            max-width: none;
            padding: 1.5rem 2rem;
            background: var(--my-account-order-list-search-field-background);

            &-Wrapper {
                background: var(--my-account-order-list-search-wrapper-background);
                margin-right: 0;
                padding: .5rem 1rem;
            }

            &-Input {
                border: 0;
            }

            &-ActionItem {
                right: 1rem;
            }
        }

        .SearchOverlay {
            &-Results {
                width: calc(100% + 2rem);
                margin: 0 -1rem;
                padding: 2rem;
            }
        }
    }

    &-SearchHeading {
        padding: .8rem 2rem;
        margin: 0;
        background: var(--my-account-order-list-search-heading-background);
        border-bottom: 1px solid var(--my-account-order-list-divider-background);
    }

    &-Empty {
        @include mobile {
            padding: 1.2rem;
            border-bottom: 1px solid var(--expandable-content-divider-color);
        }

        @include mobile {
            padding: 1.4rem;
        }
    }

    &-TableHeadWrapper {
        display: grid;
        grid-template-columns: auto 100px;
        grid-gap: 1.2rem;
        border-bottom: 1px solid var(--my-account-order-list-divider-background);
        background-color: var(--my-account-order-list-table-head-background);
        padding: 1.08rem 1.2rem;

        @include mobile {
            padding: 1.26rem 1.4rem;
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: .7fr 3.3fr 1fr 1fr;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0;

        @include mobile {
            display: none;
        }

        @include tablet-portrait {
            grid-template-columns: 4fr 1fr;
        }

        span {
            text-align: center;
            font-size: 1.2rem;
            color: var(--my-account-order-list-table-head-text-color);

            &:last-of-type {
                text-align: right;
            }

            &:nth-child(2) {
                @include tablet-portrait {
                    display: none;
                }
            }

            &:first-of-type {
                text-align: left;
                display: flex;
            }
        }
    }

    &-SelectAll {
        margin-top: 0;
        margin-right: 1rem;

        .input-control {
            margin-left: 0;
        }
    }
}
