/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --my-account-login-as-customer-divider-background: var(--secondary-dark-color);
    --my-account-login-as-customer-search-heading-background: var(--secondary-base-color);
    --my-account-login-as-customer-search-field-background: var(--secondary-base-color);
    --my-account-login-as-customer-search-wrapper-background: white;
}

.MyAccountLoginAsCustomer {
    &-Search {
        z-index: 10;
        margin-bottom: 2rem;

        .SearchField {
            width: auto;
            max-width: none;
            display: block;
            padding: 1.5rem 2rem;
            background: var(--my-account-order-list-search-field-background);

            &-Wrapper {
                background: var(--my-account-order-list-search-wrapper-background);
                margin-right: 0;
                padding: .5rem 1rem;
            }

            &-Input {
                border: none;
            }

            &-SearchIcon {
                top: calc(50% - 14px);
            }

            &-SearchInnerWrapper {
                .SearchField-ActionIcon {
                    right: 1rem;
                }
            }
        }
    }

    &-SearchHeading {
        padding: .8rem 2rem;
        margin: 0;
        background: var(--my-account-login-as-customer-search-heading-background);
        border-bottom: 1px solid var(--my-account-login-as-customer-divider-background);
    }

    &-Table {
        width: 100%;
        min-width: 100%;
        max-width: 100%;


        tr:not(.MyAccountLoginAsCustomer-NoResult) td:last-of-type,
        th:last-of-type {
            text-align: right;
        }
    }
}